
function LoginFailure() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Login Failure Page</h1>
        </header>
      </div>
    );
  }
  
  export default LoginFailure;