import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login';
import LoginSuccess from "./pages/LoginSuccess";
import LoginFailure from "./pages/LoginFailure";

function App() {
  return (
    <React.StrictMode>
      <Routes>
        <Route path="/" exact element={ <Login /> } />
          <Route path="/login-success" exact element={ <LoginSuccess /> } />
          <Route path="/login-failure" exact element={ <LoginFailure /> } />
      </Routes>
    </React.StrictMode>
  )
};

export default App;