import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.css';
// import { useState } from 'react';

function Login() {
    //const [data, setData] = useState(null)

    const handleClick = async () => {
        try {
            // const data =
            await (await fetch(`https://stg-id.singpass.gov.sg/auth?scope=openid&response_type=code&redirect_uri=https%3A%2F%2Freact-app-with-auth.pages.dev%2Flogin-success&nonce=7863ce84-ad96-40e8-992e-09778646eb07&client_id=Wf5US5rKxvb80MvCzuRT8NR2suh0br9N&state=dGVzdCBzdHJpbmcK`,
                { method: 'GET', redirect: 'follow', mode: 'cors'}))
            // setData(data)
        } catch (err) {
            console.log(err.message)
        }
    }

    return (
        <div className="container">
            <h1>Login Page</h1>
            <Button style={{ backgroundImage:"url('/btn-singpass-login-red.png')",
                backgroundSize:"cover", width:"280px", height:"60px" }}
                    onClick={ handleClick }></Button>{' '}
            {/*{ data }*/}
        </div>
  );
}

export default Login;